import React, { useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  gsap.config()
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.defaults({ ignoreMobileResize: true })

  useEffect(() => {
    // const rows = 4
    // const columns = 2
    // const missingImages = 0
    // const frame_count = rows * columns - missingImages - 1
    // const imageWidth = 3274
    // const imageHeight = 2532
    // const horizDiff = imageWidth / columns
    // const vertDiff = imageHeight / rows

    // const viewer = document.querySelector(".logo--360 canvas")
    // const ctx = viewer.getContext("2d")
    // viewer.width = horizDiff
    // viewer.height = vertDiff

    // const image = new Image()
    // image.src = "/logo.png"
    // image.onload = () => onUpdate()

    // const obj = { num: 0 }
    // gsap.to(obj, {
    //   num: frame_count,
    //   ease: "steps(" + frame_count + ")",
    //   scrollTrigger: {
    //     trigger: ".logo--360",
    //     start: "top top",
    //     end: "+=" + imageHeight,
    //     pin: true,
    //     anticipatePin: 1,
    //     scrub: 1,
    //   },
    //   onUpdate,
    // })

    // function onUpdate() {
    //   ctx.clearRect(0, 0, horizDiff, vertDiff)
    //   const x = Math.round((obj.num % columns) * horizDiff)
    //   const y = Math.round(Math.floor(obj.num / columns) * vertDiff)
    //   ctx.drawImage(image, x, y, horizDiff, vertDiff, 0, 0, horizDiff, vertDiff)
    // }
  }, [])

  return (
    <Layout page="" route="index">
      <Seo
        title="Hello"
        description={
          "Portfolio of Alistair Bancroft, London based Senior Front End Lead. Currently working for John Lewis Partnership."
        }
      />

      <div data-background="purple" className="h-screen">
        <section className="flex items-center py-28 md:py-48 px-5 md:px-[2.25vw] lg:px-[3vw]">
            <header className="">
                <img
                  alt="0.7734 Ltd"
                  className="h-full object-contain w-full"
                  src="/logo.gif"
                />
            </header>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
